import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import pt from "vuetify/lib/locale/pt";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        orange: "#F3643D",
        primary: "#F4693D",
        secondary: "#606060",
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
        neutral: "#FFFFFF",
        background: "#F3F3F3",
        foreground: "#FFFFFF",
        gray1: "#2B2B2B",
        gray2: "#414141",
        gray3: "#606060",
        gray4: "#777777",
        gray5: "#929292",
        gray6: "#D6D6D6",
        gray7: "#F3F3F4",
      },
    },
  },
  lang: {
    locales: { pt },
    current: "pt",
  },
});
