import { render, staticRenderFns } from "./AttendanceAuthorize.vue?vue&type=template&id=6772dabb&scoped=true"
import script from "./AttendanceAuthorize.vue?vue&type=script&lang=js"
export * from "./AttendanceAuthorize.vue?vue&type=script&lang=js"
import style0 from "./style.scss?vue&type=style&index=0&id=6772dabb&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6772dabb",
  null
  
)

export default component.exports