import api from "./index";

export async function createAttendance(body) {
  return api.post(`/attendances`, body);
}

export async function updateAttendance(id, body) {
  return api.put(`/attendances/${id}`, body);
}

export async function getAttendance(id, params) {
  return api.get(`/attendances/${id}`, { params });
}

export async function getQueuePosition(id) {
  return api.get(`/attendances/${id}/queue`);
}

export async function createAnswer(id, body) {
  return api.put(`/attendances/${id}/answer`, body);
}

export async function resendAttendanceCode(id) {
  return api.post(`/attendances/${id}/code/resend`);
}
