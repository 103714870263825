import socket from "./index";
import { eventBus } from "@/eventBus.js";

export async function newAttendance(callback) {
  socket.emit("attendance:new", callback);
}

socket.on("attendance:started", (data) => {
  eventBus.$emit("attendance:started", data);
});

export async function attendanceNewMessage(data, callback) {
  socket.emit("attendance-messages:new", data, callback);
}

socket.on("attendance-messages", (data) => {
  eventBus.$emit("attendance-messages", data);
});

export async function attendanceRequest(data, callback) {
  socket.emit("attendance:request", data, callback);
}

export function attendanceFinish(data, callback) {
  socket.emit("attendance:finish", data, callback);
}

socket.on("attendance:finished", (data) => {
  eventBus.$emit("attendance:finished", data);
});

export function attendanceCancel(data, callback) {
  socket.emit("attendance:cancel", data, callback);
}

socket.on("attendance:canceled", (id) => {
  eventBus.$emit("attendance:canceled", id);
});

export async function attendanceJoin(data, callback) {
  socket.emit("attendance:join", data, callback);
}

socket.on("attendance:queue", () => {
  eventBus.$emit("attendance:queue");
});

export async function attendanceDialog(data, callback) {
  socket.emit("attendance:dialog", data, callback);
}

export async function attendanceInactive(data) {
  socket.emit("attendance:inactive", data);
}

socket.on("attendance:transferred", (data) => {
  eventBus.$emit("attendance:transferred", data);
});
