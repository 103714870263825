<template>
  <v-container fill-height>
    <div
      v-if="loading || !attendance"
      class="d-flex align-center justify-center my-10"
    >
      <v-progress-circular color="primary" indeterminate />
    </div>

    <div v-else class="d-flex flex-column fill-width">
      <v-row justify="center">
        <v-col cols="12" sm="8" md="5" lg="4" xl="3">
          <CustomChat
            class="mb-4"
            :chat="chat"
            :attendant="{
              name: attendance.attendant.name,
              icon: 'mdi-account',
            }"
            :height="'80vh'"
            :finalizeLabel="'Finalizar Atendimento'"
            :readMode="readMode"
            @send-message="sendMessage($event)"
            @finalize="finishAttendance()"
            @attach-file="uploadFile($event)"
          />

          <div v-if="readMode" class="d-flex align-center justify-center">
            <v-btn to="/home" class="text-none" text> Voltar </v-btn>
          </div>
        </v-col>
      </v-row>

      <RatingDialog ref="ratingDialog" />
    </div>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { getAttendance } from "@/services/attendance";
import { uploadFile } from "@/services/files";
import { attendanceNewMessage, attendanceJoin } from "@/socket/attendance";
import { handleAlert, handleFinishAttendance } from "@/utils";
import { eventBus } from "@/eventBus.js";
import CustomChat from "@/components/customChat/CustomChat";
import RatingDialog from "@/views/chatBot/partials/ratingDialog/RatingDialog.vue";

export default {
  name: "Attendance",

  components: {
    CustomChat,
    RatingDialog,
  },

  data() {
    return {
      loading: true,
      readMode: false,
      chat: {
        messages: [],
      },
    };
  },

  mounted() {
    this.getAttendance();
    this.openEventBus();
  },

  beforeDestroy() {
    this.closeEventBus();
  },

  computed: {
    ...mapState(["protocol", "attendance"]),

    _code() {
      return this.$route.query.code || this.attendance?.code;
    },
  },

  methods: {
    ...mapActions(["setProtocol", "setAttendance"]),

    sendMessage(data) {
      const payload = {
        attendanceID: this.attendance._id,
        text: data.message,
      };

      attendanceNewMessage(payload, (error) => {
        if (error) return; // @todo
      });
    },

    handleMessages(event) {
      const isRequester =
        !event.author._id || event.author._id === this.attendance.requester._id;

      this.chat.messages.push({
        me: isRequester,
        createdAt: event.createdAt,
        author: {
          name: event.author.name,
          image: "",
        },
        message: event.text,
      });
    },

    async getAttendance() {
      const attendanceID = this.$route.params.id || this.attendance?._id;
      try {
        if (!attendanceID) return;

        const { data } = await getAttendance(attendanceID, {
          code: this._code,
        });

        this.setAttendance(data);
        this.setProtocol(data.protocol);

        this.chat = {
          ...data,
          messages: data.messages.map((el) => {
            const isRequester =
              !el.author?._id ||
              el.author._id === this.attendance.requester._id;

            return {
              me: isRequester,
              createdAt: el.createdAt,
              message: el.text,
            };
          }),
        };

        if (["in-progress"].includes(data.status)) {
          this.handleJoin();
          return this.$router
            .push({
              path: `/attendance/${this.attendance._id}`,
            })
            .catch(() => {});
        }

        if (["waiting"].includes(data.status)) {
          return this.$router.push({ path: "/queue" }).catch(() => {});
        }

        if (["canceled", "finished", "time-out"].includes(data.status)) {
          return (this.readMode = true);
        }

        // chat not valid
        this.setAttendance(null);
        this.setProtocol("");

        this.$router.push({ path: "/home" });
      } catch (error) {
        if (error?.status === 404) {
          return this.$router
            .push({
              path: `/attendance/${attendanceID}/authorize`,
            })
            .catch(() => {});
        }

        this.handleAlert(
          error.response?.data?.message || error.message,
          "error"
        );
      } finally {
        this.loading = false;
      }
    },

    async uploadFile(event) {
      try {
        let formData = new FormData();
        formData.append("file", event.file);

        const { data } = await uploadFile(formData);
        this.sendMessage({ message: data.publicUrl });
      } catch (error) {
        this.handleAlert(
          error.response?.data?.message || error.message,
          "error"
        );
      }
    },

    handleJoin() {
      const payload = { protocol: this.attendance.protocol };

      attendanceJoin(payload, (error) => {
        if (error) return; // @todo
      });
    },

    finishAttendance() {
      const msg = {
        message: "Atendimento finalizado pelo solicitante",
      };

      this.sendMessage(msg);
      this.handleFinishAttendance();
    },

    attendanceTransferred() {
      this.handleAlert(
        "Seu atendimento foi transferido, você foi redirecionado para fila de espera",
        "info",
        true
      );

      this.$router.push({ path: "/queue" });
    },

    openEventBus() {
      eventBus.$on("attendance-messages", this.handleMessages);
      eventBus.$on("attendance:finished", this.handleFinish);
      eventBus.$on("attendance:finish", this.finishAttendance);
      eventBus.$on("attendance:transferred", this.attendanceTransferred);
    },

    closeEventBus() {
      eventBus.$off("attendance-messages", this.handleMessages);
      eventBus.$off("attendance:finished", this.handleFinish);
      eventBus.$off("attendance:finish", this.finishAttendance);
      eventBus.$off("attendance:transferred", this.attendanceTransferred);
    },

    handleRating() {
      this.$router.push({ path: "/rating" }).catch(() => {});
    },

    handleCancel() {
      this.handleAlert("Atendimento finalizado", "info");
      this.handleRating();
    },

    handleFinish(data) {
      this.setAttendance(data);
      this.handleAlert("Atendimento finalizado", "info");
      this.handleRating();
    },

    handleFinishAttendance,
    handleAlert,
  },
};
</script>

<style src="./style.scss" lang="scss" scoped />
