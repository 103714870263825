import Vue from "vue";
import Vuex from "vuex";
import VuexPersist from "vuex-persist";
import api from "@/services";

Vue.use(Vuex);

const vuexLocal = new VuexPersist({
  key: "localStorage",
  storage: window.localStorage,
  modules: ["attendance", "accessToken", "refreshToken", "user"],
});

export default new Vuex.Store({
  state: {
    protocol: "",
    attendance: null,
    accessToken: "",
    refreshToken: "",
    module: "",
    user: null,
  },

  getters: {
    authenticated: ({ accessToken, refreshToken, user }) =>
      !!accessToken && !!refreshToken && !!user,
    protocol: ({ protocol }) => protocol,
    attendance: ({ attendance }) => attendance,
    accessToken: ({ accessToken }) => accessToken,
    refreshToken: ({ refreshToken }) => refreshToken,
    module: ({ module }) => module,
    user: ({ user }) => user,
  },

  mutations: {
    SET_PROTOCOL(state, payload) {
      state.protocol = payload;
    },

    SET_ATTENDANCE(state, payload) {
      state.attendance = payload;
    },
    SET_ACCOUNT(state, payload) {
      state.user = payload;
    },
    SET_ACCESS_TOKEN(state, payload) {
      state.accessToken = payload;
    },

    SET_REFRESH_TOKEN(state, payload) {
      state.refreshToken = payload;
    },
  },

  actions: {
    setProtocol({ commit }, payload) {
      commit("SET_PROTOCOL", payload);
    },

    setAttendance({ commit }, payload) {
      commit("SET_ATTENDANCE", payload);
    },
    setAccessToken({ commit }, payload) {
      commit("SET_ACCESS_TOKEN", payload);
      api.defaults.headers.common["Authorization"] = payload;
    },
    setRefreshToken({ commit }, payload) {
      commit("SET_REFRESH_TOKEN", payload);
    },
    setUser({ commit }, payload) {
      commit("SET_ACCOUNT", payload);
    },
    setSignIn({ dispatch }, payload) {
      dispatch("setAccessToken", payload.accessToken);
      dispatch("setRefreshToken", payload.refreshToken);
      dispatch("setUser", payload.user);
    },
    setSignOut({ dispatch }) {
      dispatch("setAccessToken", "");
      dispatch("setRefreshToken", "");
      dispatch("setUser", null);
    },
  },

  plugins: [vuexLocal.plugin],

  modules: {},
});
