<template>
  <v-dialog v-model="dialog" :max-width="width">
    <v-card class="rounded-lg pa-6" color="neutral">
      <div class="header d-flex flex-column mb-2">
        <div class="text-h5 font-weight-bold primary--text">
          Pesquisa de Satifação
        </div>
      </div>
      <div class="content">
        <v-form v-model="valid" ref="form" class="mt-4">
          <v-row>
            <span class="ml-3">{{ question }}</span>
            <v-col cols="12">
              <v-rating
                color="primary"
                background-color="gray4"
                empty-icon="mdi-star-outline"
                full-icon="mdi-star"
                hover
                length="5"
                size="51"
                v-model="form.answer"
              ></v-rating>
            </v-col>
          </v-row>
          <div class="footer d-flex mt-6">
            <v-spacer />
            <v-btn
              class="ml-3 font-weight-regular"
              elevation="1"
              color="primary"
              @click="submit()"
            >
              <span> Avaliar </span>
            </v-btn>
          </div>
        </v-form>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { getRating } from "@/services/rating";
import { createAnswer } from "@/services/attendance";

export default {
  name: "RatingDialog",

  data() {
    return {
      valid: false,
      dialog: false,
      attendanceID: "",
      question: "",
      form: {
        ratingID: "",
        answer: 3,
      },
    };
  },

  props: {
    width: {
      type: [String, Number],
      default: "600",
    },
  },

  methods: {
    async submit() {
      const id = this.attendanceID;
      const values = {
        ratingID: this.form.ratingID,
        answer: this.form.answer.toString(),
      };
      await createAnswer(id, values);
      this.$router.push({ path: "/home" }).catch(() => {});
      this.$emit("success");
      this.close();
    },

    async getQuestion() {
      const { data } = await getRating();
      this.question = data[0].text;
      this.form.ratingID = data[0]._id;
    },

    open(id) {
      this.attendanceID = id;
      this.getQuestion();
      this.dialog = true;
    },

    close() {
      this.resetForm();
      this.dialog = false;
    },

    resetForm() {
      this.form.ratingID = "";
      this.form.answer = null;

      if (this.$refs.form) this.$refs.form.resetValidation();
    },
  },
};
</script>

<style src="./style.scss" lang="scss" />
