<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" sm="10" md="8" lg="6" xl="5">
        <v-card outlined elevation="19" class="transparent-card">
          <div class="header d-flex flex-column mb-2">
            <div class="text-h5 font-weight-light text-center mt-15 mb-10">
              Conclusão de Atendimento
            </div>
          </div>
          <div class="content">
            <v-form ref="form" class="mt-4">
              <v-row justify="center" class="text-center">
                <v-col cols="12">
                  <span class="text-body-1 font-weight-light">
                    A sua solicitação foi
                    <span class="font-weight-bold gray4--text">atendida</span>?
                  </span>
                  <v-col cols="12" class="d-flex justify-center">
                    <v-radio-group v-model="form.answered" row>
                      <v-radio label="Sim" value="true" />
                      <v-radio label="Não" value="false" />
                    </v-radio-group>
                  </v-col>
                </v-col>
                <v-col cols="12" class="px-16">
                  <span class="text-body-2 font-weight-light gray3--text">
                    Um e-mail será enviado com todos os dados sobre o
                    atendimento. Nele você encontrará informações como o
                    protocolo e o resultado do atendimento.
                  </span>
                </v-col>
              </v-row>
              <div class="footer d-flex mt-6 justify-center mb-7">
                <v-btn
                  class="ml-3 font-weight-regular"
                  elevation="1"
                  color="success"
                  @click="submit()"
                >
                  <span> Prosseguir </span>
                </v-btn>
              </div>
            </v-form>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import { updateAttendance } from "@/services/attendance";
import { handleAlert } from "@/utils";
export default {
  name: "Rating",
  data() {
    return {
      form: {
        answered: "true",
      },
    };
  },
  computed: {
    ...mapState(["attendance"]),
    _attendanceId() {
      return this.$store.state.attendance._id;
    },
  },
  methods: {
    async submit() {
      const id = this._attendanceId;
      const values = {
        rating: {
          answered: this.form.answered,
        },
      };
      try {
        await updateAttendance(id, values);
        this.$router.push("/rating-form");
      } catch (error) {
        this.handleAlert(
          error.response?.data?.message || error.message,
          "error"
        );
      }
    },
    handleAlert,
  },
};
</script>

<style src="./style.scss" lang="scss" />
