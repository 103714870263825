<template>
  <v-container fill-height>
    <v-row justify="center">
      <v-col cols="12" sm="8" md="5" lg="4" xl="3">
        <CustomChat
          ref="customChat"
          :chat="chat"
          :attendant="{
            name: 'Chat Bot',
            icon: 'mdi-android',
          }"
          :finalizeLabel="'Cancelar Atendimento'"
          :height="'80vh'"
          :disabled="readMode || autofill"
          :hideAttach="true"
          @send-message="sendMessage($event)"
          @finalize="handleFinalize()"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { postDialog } from "@/services/dialogflow";
import { updateAttendance } from "@/services/attendance";
import { attendanceRequest, attendanceDialog } from "@/socket/attendance";
import { handleAlert, handleFinishAttendance } from "@/utils";
import { eventBus } from "@/eventBus.js";
import CustomChat from "@/components/customChat/CustomChat";

import moment from "moment";
moment.locale("pt-BR");

import _ from "lodash";

export default {
  name: "ChatBot",

  components: {
    CustomChat,
  },

  data() {
    return {
      readMode: false,
      started: false,
      autofill: false,
      chat: {
        messages: [
          {
            me: false,
            createdAt: moment(),
            message: "Podemos seguir com o atendimento?",
            options: [
              {
                label: "Sim, podemos seguir",
                value: "Sim, podemos seguir",
              },
            ],
          },
        ],
      },
      attendanceData: {
        name: "",
        cpf: "",
        email: "",
        phone: "",
        comarcaID: "",
        deskID: "",
      },
      payload: {},
    };
  },

  created() {
    if (!this.protocol) return this.$router.push({ path: "/home" });
    this.openEventBus();
  },

  beforeDestroy() {
    this.closeEventBus();
  },

  computed: {
    ...mapState(["user", "protocol", "attendance"]),

    _authenticated() {
      return this.$store.getters.authenticated;
    },
  },

  methods: {
    handleAlert,

    ...mapActions(["setProtocol", "setAttendance"]),

    async sendMessage(event) {
      try {
        if (!this.started) {
          this.started = true;

          if (this._authenticated) this.autofill = true;
        }

        if (event.key) {
          _.set(this.payload, event.key, event.value || event.message);
        }

        this.setChatMessage(event.message, true);

        // send to socket user message
        attendanceDialog(
          {
            protocol: this.protocol,
            message: {
              author: "user",
              text: event.message,
            },
          },
          (error, data) => {
            if (error) return; // @todo

            this.setAttendance(data);
          }
        );
        //

        await updateAttendance(this.attendance._id, this.payload);

        const payload = {
          text: event.message,
        };

        const { data } = await postDialog(this.protocol, payload);
        // send to socket bot message
        attendanceDialog(
          {
            protocol: this.protocol,
            message: {
              author: "chatbot",
              text: data.phrase,
            },
          },
          (error, data) => {
            if (error) return; // @todo

            this.setAttendance(data);
          }
        );
        //

        if (data.phrase) {
          let options = [];

          if (data.type === "select")
            options = data.options?.map((el) => ({
              value: el.key,
              label: el.value,
            }));
          else
            options = data.options?.map((el) => ({
              value: el._id,
              label: el.name,
            }));

          this.setChatMessage(
            data.phrase,
            false,
            options,
            data.mask,
            data.rules,
            data.key,
            data.next
          );
        }

        if (data.next === "finish") {
          this.readMode = true;
        }

        if (event.next === "queue") {
          attendanceRequest({ protocol: this.protocol }, (error, data) => {
            if (error) return; // @todo

            this.setAttendance(data);
            this.readMode = true;

            setTimeout(() => {
              this.$router.push({ path: "/queue" });
            }, 3000);
          });
        }

        if (this.autofill && data.key.includes("requester")) {
          const keys = data.key.replace("requester", "user").split(".");
          this.$refs.customChat.handleAutofill(this[keys[0]][keys[1]]);

          return;
        }

        if (this.autofill) this.autofill = false;
      } catch (error) {
        if (
          error.data.details[0].message ===
          '"requester.email" must be a valid email'
        ) {
          this.handleAlert("E-mail inválido ou incorreto", "error");
        } else
          this.handleAlert(
            error.response?.data?.message || error.data.message,
            "error"
          );
      }
    },

    openEventBus() {
      eventBus.$on("attendance:started", this.handleStartChat);
    },

    closeEventBus() {
      eventBus.$off("attendance:started", this.handleStartChat);
    },

    handleStartChat(event) {
      this.setAttendance(event);
      this.$router.push({ path: `/attendance/${event._id}` }).catch(() => {});
    },

    setChatMessage(message, me, options, mask, rules = [], key, next) {
      this.chat.messages.push({
        createdAt: moment(),
        message,
        me,
        options,
        mask,
        rules,
        key,
        next,
      });
    },

    handleFinalize() {
      this.handleFinishAttendance();

      this.$router.push({ path: "/home" }).catch(() => {});
    },

    handleFinishAttendance,
  },
};
</script>

<style src="./style.scss" lang="scss" scoped />
