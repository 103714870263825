<template>
  <v-container fill-height>
    <div class="d-flex flex-column fill-width">
      <v-row class="mb-2" justify="center">
        <v-col cols="3">
          <v-img :src="require('@/assets/queue_figure.png')" />
        </v-col>
      </v-row>

      <div class="d-flex flex-column align-center mb-8">
        <div class="text-h6 font-weight-regular mb-2">
          Você está na fila de Atendimento
        </div>

        <div class="text-body-1 font-weight-light mb-2">
          Esta fila será atualizada em tempo real<br />
          conforme os atendimentos ocorram.
        </div>

        <div class="text-h6 font-weight-regular mb-2">
          Sua posição na fila: {{ queuePosition }}º
        </div>

        <div class="text-h6 font-weight-regular mb-2">
          Seu numero de protocolo é: {{ protocol }}
        </div>

        <v-btn class="text-none" text @click="finalizeAttendance()">
          Cancelar
        </v-btn>
      </div>

      <v-row justify="center">
        <v-col cols="8">
          <v-card class="white" flat>
            <v-row no-gutters>
              <v-col
                v-for="(item, i) in _attendanceData"
                :key="i"
                class="pa-4"
                cols="6"
                md="3"
              >
                <div class="d-flex align-center fill-height side-border pl-3">
                  <div class="d-flex flex-column">
                    <div class="text-caption mb-n1">
                      {{ item.label }}
                    </div>

                    <div class="text-body-1 font-weight-bold gray2--text">
                      {{ item.value }}
                    </div>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { getQueuePosition } from "@/services/attendance";
import { handleAlert, handleFinishAttendance } from "@/utils";
import { eventBus } from "@/eventBus.js";

export default {
  name: "Queue",

  data() {
    return {
      loading: false,
      queueMode: false,
      queuePosition: null,
      payload: {},
    };
  },

  created() {
    if (!this.protocol) return this.$router.push({ path: "/home" });

    this.openEventBus();
  },

  beforeMount() {
    this.getPosition();
  },

  beforeDestroy() {
    this.closeEventBus();
  },

  computed: {
    ...mapState(["protocol", "attendance"]),

    _attendanceData() {
      if (!this.attendance) return [];

      return [
        {
          label: "Comarca",
          value: this.attendance.comarca.name,
        },
        {
          label: "Vara",
          value: this.attendance.vara.name,
        },
        {
          label: "Balcão",
          value: this.attendance.desk.name,
        },
        {
          label: "Tipo",
          value: this.attendance.attendanceType.name,
        },
      ];
    },
  },

  methods: {
    ...mapActions(["setProtocol", "setAttendance"]),

    async getPosition() {
      try {
        const { data } = await getQueuePosition(this.attendance._id);
        this.queuePosition = data.position;
        this.queueMode = true;
      } catch (error) {
        console.log(error);
      }
    },

    openEventBus() {
      eventBus.$on("attendance:started", this.handleStartChat);
      eventBus.$on("attendance:queue", this.getPosition);
      eventBus.$on("attendance:canceled", this.handleCancel);
      eventBus.$on("attendance:finished", this.handleCancel);
    },

    closeEventBus() {
      eventBus.$off("attendance:started", this.handleStartChat);
      eventBus.$off("attendance:queue", this.getPosition);
      eventBus.$off("attendance:canceled", this.handleCancel);
      eventBus.$off("attendance:finished", this.handleCancel);
    },

    handleStartChat(event) {
      this.setAttendance(event);
      this.$router.push({ path: `/attendance/${event._id}` }).catch(() => {});
    },

    handleCancel() {
      this.handleAlert("Atendimento cancelado com sucesso!", "info");
      this.$router.push({ path: "/home" }).catch(() => {});
    },

    finalizeAttendance() {
      this.handleFinishAttendance();
      this.$router.push({ path: "/home" }).catch(() => {});
    },

    handleAlert,

    handleFinishAttendance,
  },
};
</script>

<style src="./style.scss" lang="scss" scoped />
