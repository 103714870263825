import api from "./index";

export async function postGovbr(body) {
  return api.post(`/auth/govbr`, body);
}

export async function loadSessionGovbr() {
  return api.get("/auth/govbr");
}

export async function signOutGovBr() {
  return api.delete("/auth");
}
