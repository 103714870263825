<template>
  <v-app id="app" class="app">
    <v-app-bar color="transparent" flat>
      <div class="d-flex justify-center align-center fill-width pt-10">
        <v-img :src="require('@/assets/logo.png')" max-width="20rem" />
      </div>
    </v-app-bar>

    <v-main class="py-12">
      <router-view />
    </v-main>

    <AlertBar ref="alertBar" />
  </v-app>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { loadSessionGovbr, signOutGovBr } from "@/services/govbr";
import {
  attendanceCancel,
  attendanceFinish,
  attendanceInactive,
} from "@/socket/attendance";
import { login, logout } from "@/socket/auth";
import AlertBar from "./components/alertBar/AlertBar.vue";
import socket from "@/socket";

export default {
  name: "App",

  components: {
    AlertBar,
  },

  created() {
    window.addEventListener("beforeunload", this.handleDestroy);

    if (this.attendance?._id) {
      this.$router.push(`/attendance/${this.attendance._id}`).catch(() => {});
    }
  },

  beforeMount() {
    this.$root.$on("alert", this.handleAlert);
    this.$root.$on("sign-out", this.signOut);
    this.$root.$on("attendance:finish", this.handleAttendanceFinish);
  },

  computed: {
    ...mapState(["attendance"]),
  },

  methods: {
    ...mapActions(["setUser", "setSignOut", "setProtocol", "setAttendance"]),

    handleDestroy() {
      if (this.attendance) {
        this.handleAttendanceFinish();
      }

      this.$root.$off("alert", this.handleAlert);
      this.$root.$off("sign-out", this.signOut);
      this.$root.$off("attendance:finish", this.handleAttendanceFinish);

      socket.disconnect();
    },

    async handleSession() {
      const token = this.$store.getters.accessToken;

      if (token) {
        try {
          const { data } = await loadSessionGovbr();

          await login({ token: data.accessToken }, (error) => {
            if (error) return this.signOut();

            this.setUser(data);
          });
        } catch {
          this.signOut();
        }
      }
    },

    async signOut() {
      try {
        await logout();
        await signOutGovBr();

        await this.setSignOut();
        this.$router.push({ path: "/home" }).catch(() => {});
      } catch (error) {
        this.handleAlert({
          message: error.response?.data?.message || error.message,
          type: "error",
        });
      }
    },

    handleAttendanceInactive(_self) {
      const payload = { attendanceID: _self.attendance._id };
      attendanceInactive(payload);
    },

    handleAttendanceFinish() {
      try {
        if (!this.attendance) return;

        const payload = { attendanceID: this.attendance?._id };

        if (["pending", "waiting"].includes(this.attendance.status)) {
          return attendanceCancel(payload, () => {
            this.setAttendance(null);
            this.setProtocol("");
            this.handleAlert({
              message: "Atendimento cancelado",
              type: "info",
              fixed: true,
            });
            this.$router.push({ path: "/home" }).catch(() => {});
          });
        }

        if (["in-progress"].includes(this.attendance.status)) {
          return attendanceFinish(payload, (err, data) => {
            if (err) {
              throw new Error(err.message);
            }

            this.setAttendance(data);
            this.handleAlert({
              message: "Atendimento finalizado!",
              type: "info",
              fixed: true,
            });
          });
        }

        this.setAttendance(null);
        this.setProtocol("");
        this.$router.push({ path: "/home" }).catch(() => {});
      } catch (error) {
        console.log(error.message);
        this.setAttendance(null);
        this.setProtocol("");
        this.handleAlert({
          message: "Atendimento finalizado!",
          type: "error",
          fixed: true,
        });

        this.$router.push({ path: "/home" }).catch(() => {});
      }
    },

    handleAlert(event) {
      this.$refs.alertBar.handleAlert(event);
    },
  },
};
</script>

<style lang="scss" scoped>
.app {
  background: rgba(17, 17, 17, 0.089) !important;
}
</style>
