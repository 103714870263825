<template>
  <v-container fill-height>
    <div class="d-flex flex-column">
      <v-row class="mb-10" justify="center">
        <v-col cols="7" md="6" lg="4" xl="3">
          <v-card class="pa-8" height="100%" flat @click="handleStartChat()">
            <v-row class="mb-2" justify="center">
              <v-col cols="8">
                <v-img :src="require('@/assets/new_figure.png')" />
              </v-col>
            </v-row>

            <div class="text-h6 font-weight-light text-center">
              Iniciar Novo Atendimento
            </div>
          </v-card>
        </v-col>

        <v-col cols="7" md="6" lg="4" xl="3">
          <v-card
            class="pa-8"
            height="100%"
            flat
            :disabled="!this.user"
            @click="handleHisotoricNagigate()"
          >
            <v-row class="mb-2" justify="center">
              <v-col cols="7">
                <v-img :src="require('@/assets/history_figure.png')" />
              </v-col>
            </v-row>

            <div class="text-h6 font-weight-light text-center">
              Histórico de Atendimento
            </div>
          </v-card>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col cols="8" xl="7">
          <div class="text-body-1 font-weight-light text-center">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque sit
            amet vehicula leo. Nullam sollicitudin lorem lectus, eu maximus
            nulla efficitur nec. Ut venenatis vitae nisl eget convallis. Aliquam
            erat volutpat. Maecenas ac eros est.
          </div>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { postGovbr } from "@/services/govbr";
import { newAttendance } from "@/socket/attendance";
import { login } from "@/socket/auth";
import { handleAlert, formatDocument } from "@/utils";

export default {
  name: "Home",

  computed: {
    ...mapState(["user"]),
  },

  methods: {
    ...mapActions(["setProtocol", "setAttendance", "setSignIn"]),
    async login() {
      try {
        const body = {
          CODE: "1",
          REDIRECT_URI: "url",
          CODE_VERIFIER: "x",
        };

        const { data } = await postGovbr(body);

        this.setSignIn({
          accessToken: data.accessToken,
          refreshToken: data.refreshToken,
          user: data.person,
        });

        await login({ token: data.accessToken }, (error) => {
          if (error) return this.signOut();

          this.handleAlert("Acesso efetuado com sucesso!", "success");
        });
      } catch (error) {
        console.log(error);
      }
    },

    signOut() {
      this.$root.$emit("sign-out");
    },

    handleStartChat() {
      console.log("attendance:new");

      newAttendance((error, data) => {
        if (error) return; // @todo

        this.setAttendance(data);
        this.setProtocol(data.protocol);

        this.$router.push("/chat-bot").catch(() => {});
      });
    },

    handleHisotoricNagigate() {
      this.$router.push("/historic").catch(() => {});
    },

    handleAlert,

    formatDocument,
  },
};
</script>

<style src="./style.scss" lang="scss" />
